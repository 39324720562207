<template>
  <router-view></router-view>
</template>
<script>
export default {
  mounted() {
    // if (this._isMobile()) {
    //   window.location.href = "https://h5.mtmexc.com/";
    //   console.log('手机端')
    // }
  },
  methods: {
    _isMobile() {
      let flag = navigator.userAgent.match(/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i)
      return flag
    }
  }
}
</script>
<style lang="less">
.container-h {
  min-height: calc(100vh - 50px - 62px);
}

.container-h2 {
  min-height: calc(100vh - 50px - 360px);
}

.app {
  padding-top: 50px;
}

body {
  --theme-green: #398D4F;
  --theme-red: #E5564E;
  --theme-main: #121212;
  --theme-grey: #999999;

  .el-message-box {
    background: #161A1E;
    box-shadow: 0px 2px 105px 26px rgba(44, 49, 56, 0.1);
    border: none;

    .el-message-box__title {
      color: #FFF;
    }

    .el-message-box__message {
      color: #FFF;
    }

    .btns {
      .el-button {
        background-color: var(--theme-main);
        color: #FFF;
        border: 1px solid var(--theme-main);

        &:nth-of-type(2) {
          background-color: #FFF;
          color: var(--theme-main);
          border: 1px solid var(--theme-main);
        }
      }
    }


  }
}
.el-button {
  &.bg-theme-green {
    &:focus,
    &:hover {
      background-color: var(--theme-green);
    }
  }
}

.citySelect {

}
.el-select-dropdown__item  {
    display: flex;
}


.el-dropdown-menu {
  // background-color: #4a4a4a !important;
  // border: 1px solid #4a4a4a !important;


  .el-dropdown-menu__item {
    font-size: 16px;
    line-height: 3;
    color: #FFF !important;
    color: #212121 !important;


    &:focus,
    &:not(.is-disabled):hover {
      // background-color: #4a4a4a !important;
    }
  }
}


.el-table__empty-block {
  .el-table__empty-text {
    line-height: 1.2;
    padding: 50px 0;
  }

  .iconfont {
    font-size: 120px;
    color: var(--theme-grey);
  }

  span {

    color: var(--theme-grey);
  }
}


.el-dialog {}
</style>
